import { sharedDashboardQuery as SharedDashboardQueryType } from "./__generated__/sharedDashboardQuery.graphql"
import styles from "./shared-dashboard.module.scss"
import { Box, Group, Stack, Text, Title } from "@mantine/core"
import Logo from "@shared/assets/logo.svg"
import { useState } from "react"
import { graphql, useLazyLoadQuery } from "react-relay"
import { useParams } from "react-router-dom"

const BASE_URL = import.meta.env.VITE_METABASE_EMBEDDING_BASE_URL
const BASE_UI_ATTRIBUTES = encodeURIComponent(
  "top_nav=false&side_nav=false&header=false&action_buttons=false"
)

const SharedDashboardQuery = graphql`
  query sharedDashboardQuery($uuid: String!) {
    getPublicSharedDashboard(uuid: $uuid) {
      sharedDashboard {
        name
        metabaseDashboardId
        client {
          name
        }
      }
      metabaseJwtToken
      errors
    }
  }
`

export const SharedDashboard = () => {
  const { uuid } = useParams()
  const {
    getPublicSharedDashboard: { sharedDashboard, metabaseJwtToken },
  } = useLazyLoadQuery<SharedDashboardQueryType>(SharedDashboardQuery, {
    uuid: uuid ?? "",
  })
  const [frameLoaded, setFrameLoaded] = useState(false)

  const token = metabaseJwtToken ?? ""
  const dashboardId = sharedDashboard?.metabaseDashboardId?.toString() ?? ""

  const iframeURL = `${BASE_URL}/auth/sso?jwt=${token}&return_to=/dashboard/${dashboardId}?${BASE_UI_ATTRIBUTES}`

  return (
    <Box className={styles.SharedLayout}>
      <Group justify="space-between" className={styles.SharedLayout__TopBar}>
        <img src={Logo} />
        <Text size="lg" fw={700} c={"dark.3"}>
          {sharedDashboard?.client?.name}
        </Text>
      </Group>
      <Box>
        {frameLoaded && (
          <Text
            size="xl"
            fw={700}
            c="gray"
            className={styles.SharedLayout__TitleBar}
          >
            {sharedDashboard?.name}
          </Text>
        )}
      </Box>
      {metabaseJwtToken && sharedDashboard ? (
        <iframe
          src={iframeURL}
          width={"100%"}
          height={"100%"}
          loading="eager"
          className={styles.Iframe}
          onLoad={() => setFrameLoaded(true)}
        />
      ) : (
        <Stack justify="center" align="center" gap={0}>
          <Title c="blue" order={1}>
            404
          </Title>
          <Title c="gray" order={2}>
            Not found
          </Title>
        </Stack>
      )}
    </Box>
  )
}
