import { navbarUserFragment$key } from "./__generated__/navbarUserFragment.graphql"
import { ClientSelector } from "./client-selector"
import { NavbarLink } from "./nabvar-link"
import styles from "./navbar.module.scss"
import SmallAlpyneLogo from "@assets/logo-small.svg"
import AlpybeLogo from "@assets/logo.svg"
import { AppShell, Avatar, Menu, Stack, Text } from "@mantine/core"
import { useDisclosure } from "@mantine/hooks"
import { useUserStore } from "@shared/store"
import { themeVars } from "@shared/theme"
import { getAvatarText } from "@shared/utils/helpers"
import {
  IconAdjustmentsHorizontal,
  IconChevronDown,
  IconChevronRight,
  IconCloudDataConnection,
  IconDatabase,
  IconFileSpreadsheet,
  IconHome,
  IconLayoutGrid,
  IconLayoutSidebarLeftCollapse,
  IconLayoutSidebarLeftExpand,
  IconServerCog,
  IconSettings,
  IconTable,
} from "@tabler/icons-react"
import { pathConstants } from "frontend/routes/path-constants"
import { useState } from "react"
import { graphql, useFragment } from "react-relay"
import { useLocation } from "react-router-dom"

const NavbarUserFragment = graphql`
  fragment navbarUserFragment on User {
    isFirmAdmin
    isSuperAdmin
    role
    firm {
      name
    }
    clients {
      count
    }
    fullName
    avatar
    ...clientSelectorClientsFragment
  }
`

type Props = {
  user: navbarUserFragment$key
}

export const Navbar = ({ user }: Props) => {
  const location = useLocation()
  const { navbarCollapsed, setNavbarCollapsed } = useUserStore()
  const data = useFragment(NavbarUserFragment, user)
  const {
    firm,
    isSuperAdmin,
    clients: { count: clientsCount },
    fullName,
    avatar,
  } = data
  const [financialOpen, financialHandlers] = useDisclosure(true)
  const [menuOpened, setMenuOpened] = useState(false)
  const [clientsOpened, setClientsOpened] = useState(false)

  return (
    <AppShell.Navbar className={styles.Navbar}>
      <AppShell.Section
        className={[
          styles.Navbar__LogoContainer,
          navbarCollapsed && styles["Navbar__LogoContainer--collapsed"],
        ].join(" ")}
      >
        <img
          src={navbarCollapsed ? SmallAlpyneLogo : AlpybeLogo}
          className={styles.Navbar__LogoContainer__Logo}
        />
        {navbarCollapsed ? (
          <IconLayoutSidebarLeftExpand
            className={styles.Navbar__LogoContainer__Collapse}
            onClick={() => setNavbarCollapsed(false)}
          />
        ) : (
          <IconLayoutSidebarLeftCollapse
            className={styles.Navbar__LogoContainer__Collapse}
            onClick={() => {
              setNavbarCollapsed(true)
              setMenuOpened(false)
              setClientsOpened(false)
            }}
          />
        )}
      </AppShell.Section>
      <AppShell.Section className={styles.Navbar__Menu}>
        <Stack gap="0.5rem">
          <Text
            className={[
              styles.Navbar__Menu__SectionTitle,
              navbarCollapsed && styles["Navbar__Menu__SectionTitle--hidden"],
            ].join(" ")}
            size="xs"
          >
            GENERAL
          </Text>
          <NavbarLink
            icon={<IconLayoutGrid />}
            label="Portfolio"
            to={pathConstants.PORTFOLIO}
            collapsed={navbarCollapsed}
          />
        </Stack>
      </AppShell.Section>
      <AppShell.Section className={styles.Navbar__Menu} grow>
        {clientsCount > 0 && (
          <Stack gap="0.5rem">
            <Text
              className={[
                styles.Navbar__Menu__SectionTitle,
                navbarCollapsed && styles["Navbar__Menu__SectionTitle--hidden"],
              ].join(" ")}
              size="xs"
            >
              CLIENT
            </Text>
            <ClientSelector
              clients={data}
              navbarCollapsed={navbarCollapsed}
              open={clientsOpened}
              setOpen={setClientsOpened}
            />
            <NavbarLink
              icon={<IconHome />}
              label="Home"
              to={pathConstants.HOME}
              collapsed={navbarCollapsed}
            />
            <NavbarLink
              icon={<IconCloudDataConnection />}
              label="Connections"
              to={pathConstants.CONNECTIONS}
              collapsed={navbarCollapsed}
            />
            <NavbarLink
              icon={<IconDatabase />}
              label="Data"
              to={pathConstants.DATA}
              collapsed={navbarCollapsed}
            />
            <Menu
              opened={menuOpened}
              onChange={setMenuOpened}
              position="right-start"
              disabled={!navbarCollapsed}
              withArrow
              arrowPosition="center"
            >
              <Menu.Target>
                <NavbarLink
                  icon={<IconTable />}
                  label="Financial"
                  to={"#"}
                  collapsed={navbarCollapsed}
                  forceActive={location.pathname.includes("financial")}
                  right={
                    financialOpen ? (
                      <IconChevronDown
                        size={16}
                        color={themeVars.colors.gray[7]}
                      />
                    ) : (
                      <IconChevronRight
                        size={16}
                        color={themeVars.colors.gray[7]}
                      />
                    )
                  }
                  onClick={() => {
                    if (navbarCollapsed) {
                      return
                    } else {
                      financialHandlers.toggle()
                    }
                  }}
                  isParent
                  tooltipDisabled={menuOpened}
                />
              </Menu.Target>
              <Menu.Dropdown ml={8}>
                <Menu.Item m={0} p={0}>
                  <NavbarLink
                    label="Statements"
                    to={pathConstants.STATEMENTS}
                    collapsed={false}
                    isMenu
                  />
                </Menu.Item>
                <Menu.Item m={0} p={0}>
                  <NavbarLink
                    label="Saved Reports"
                    to={pathConstants.SAVED_REPORTS}
                    collapsed={false}
                    isMenu
                  />
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
            {financialOpen && !navbarCollapsed ? (
              <>
                <NavbarLink
                  label="Statements"
                  to={pathConstants.STATEMENTS}
                  collapsed={navbarCollapsed}
                />
                <NavbarLink
                  label="Saved Reports"
                  to={pathConstants.SAVED_REPORTS}
                  collapsed={navbarCollapsed}
                />
              </>
            ) : (
              <></>
            )}
            <NavbarLink
              icon={<IconFileSpreadsheet />}
              label="Spreadsheets"
              to={pathConstants.SPREADSHEETS}
              collapsed={navbarCollapsed}
            />
            <NavbarLink
              icon={<IconServerCog />}
              label="Client Settings"
              to={pathConstants.CLIENT_SETTINGS}
              collapsed={navbarCollapsed}
            />
          </Stack>
        )}
      </AppShell.Section>
      <AppShell.Section className={styles.Navbar__Profile}>
        <Stack gap="0.5rem">
          {isSuperAdmin && (
            <NavbarLink
              icon={<IconAdjustmentsHorizontal />}
              label="Admin Settings"
              to={pathConstants.ADMIN_SETTINGS}
              collapsed={navbarCollapsed}
            />
          )}
          <NavbarLink
            icon={<IconSettings />}
            label={
              navbarCollapsed ? (
                `${firm.name ?? ""} Settings`
              ) : (
                <>
                  <Text size="sm" className={styles.Navbar__Profile__FirmTitle}>
                    {firm.name}
                  </Text>
                  <Text
                    size="xs"
                    className={styles.Navbar__Profile__FirmSubTitle}
                  >
                    Settings
                  </Text>
                </>
              )
            }
            to={pathConstants.FIRM_SETTINGS}
            collapsed={navbarCollapsed}
          />
          <NavbarLink
            label={
              navbarCollapsed ? (
                "Profile Settings"
              ) : (
                <Text truncate="end" maw={"11.5rem"} fw="bold">
                  {fullName ?? "My Profile"}
                </Text>
              )
            }
            icon={
              <Avatar
                size="xs"
                color="blue"
                src={avatar}
                title={fullName ?? "My Profile"}
              >
                {getAvatarText(fullName ?? "My Profile")}
              </Avatar>
            }
            to={pathConstants.PROFILE_SETTINGS}
            collapsed={navbarCollapsed}
          />
        </Stack>
      </AppShell.Section>
    </AppShell.Navbar>
  )
}
